import React from "react"
import { NavLink } from "react-router-dom"



function Header() {
  return (
    <div>

      {/*// Main Wrapper \\*/}
      <div className="sportsmagazine-main-wrapper">
        {/*// Header \\*/}
        <header id="sportsmagazine-header" className="sportsmagazine-header-one">
          {/*// MainSection \\*/}
          <div className="sportsmagazine-main-header">
            <div className="container">
              <div className="row">
                <aside className="col-md-1">
                  < NavLink to="/"
                    activeClassName="sportsmagazine-logo">
                    <img src={require('../assets/images/NS_Matrix_website_logo-preview.png')} heigth={70} width={70} alt="" />
                  </NavLink>

                </aside>
                <div className="col-md-11">
                  <div className="sportsmagazine-right-section">
                    <nav className="navbar navbar-default">
                      <div className="navbar-header">
                        <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar-collapse-1" aria-expanded="true">
                          <span className="sr-only">Toggle navigation</span>
                          <span className="icon-bar" />
                          <span className="icon-bar" />
                          <span className="icon-bar" />
                        </button>
                      </div>
                      <div className="collapse navbar-collapse" id="navbar-collapse-1">
                        <ul className="nav navbar-nav">
                          <li>
                            <NavLink to="/" exact
                              activeClassName="selected">
                              Home
                            </NavLink>
                          </li>
                          <li><a>Team</a>
                            <ul className="sportsmagazine-dropdown-menu">
                              <li>
                                <NavLink to="team-overview"
                                  activeClassName="active">
                                  Overview
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to="men-roster"
                                  activeClassName="active">
                                  Men Team Roster
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to="women-roster"
                                  activeClassName="active">
                                  Women Team Roster
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to=""
                                  activeClassName="active">
                                  Team Leaders
                                </NavLink>
                              </li>
                            </ul>
                          </li>
                          <li><a href="#">Comunity</a>
                            <ul className="sportsmagazine-dropdown-menu">
                              <li><a href="academy-overview.html">Overview</a></li>
                              <li><a href="#">Projects</a></li>
                            </ul>
                          </li>
                          <li className><a href="#">Tickets</a>
                          </li>
                          <li><a>Academy</a>
                            <ul className="sportsmagazine-dropdown-menu">
                              <li>
                                <NavLink to="/academy"
                                  activeClassName="active">
                                  Overview
                                </NavLink>
                              </li>
                              <li><a href="https://forms.gle/X6XWxDgJCvUGEN379">Signup - U20 Men</a></li>
                              <li><a href="https://forms.gle/8F8Bzw72UdotubKr8">Signup - U20 Women</a></li>
                            </ul>
                          </li>
                          <li className><a href="#">Fan Club</a>
                          </li>
                          <li><a href="https://www.hoopsstation.com/ns-matrix">SHOP</a>
                          </li>
                          <li><NavLink to="/contact" exact
                            activeClassName="active">
                            CONTACT
                          </NavLink>
                          </li>
                        </ul>
                      </div>
                    </nav>
                    <a data-toggle="modal" data-target="#searchModal" href="#" className="sportsmagazine-search-btn sportsmagazine-colorhover"><i className="fa fa-search" /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*// MainSection \\*/}
        </header>
      </div></div>
  );
}

export default Header;